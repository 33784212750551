import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import colors from '../colors'

export default class Rings extends React.PureComponent {
  render () {
    return (
    <div className={css(styles.ringsContainer)}>
      <div className={css(styles.ring, styles.orange)}>
        <div className={css(styles.ring, styles.orange)}>
          <div className={css(styles.ring, styles.orange)}>
            <div className={css(styles.ring, styles.orange)}>
              <div className={css(styles.ring)}>
                <div className={css(styles.ring)}>
                  <div className={css(styles.ring)}>
                  <div className={css(styles.ring)}>
                  <div className={css(styles.ring)}>
                  <div className={css(styles.ring, styles.purple)}>
                  <div className={css(styles.ring, styles.purple)}>
                  <div className={css(styles.ring, styles.purple)}>
                    <div className={css(styles.ring, styles.purple)}>
                      <div style={{
                        position: 'absolute',
                        top: -12, right: -12, bottom: -12, left: -12,
                        background: `radial-gradient(#FFFFFF00 25%, #FFFFFF 70%)`,
                        borderRadius: '100%',
                      }} />
                    </div>


                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

const styles = StyleSheet.create({
  ringsContainer: {
    position: 'absolute',
    top: 0, right: 0, bottom: 0, left: 0,
    height: '100%',
    width: '100%',
  },
  ring: {
    position: 'absolute',
    height: 'calc(100% + 64px)',
    width: 'calc(100% + 64px)',
    border: `1px solid ${colors.greyLight}`,
    top: '-33px',
    left: '-33px',
    borderRadius: '100%',
  },
  first: {
    backgroundColor: colors.offWhite,
    borderColor: colors.offWhite,
  },
  orange: {
    // borderColor: colors.orange,
  },
  purple: {
    // borderColor: colors.purple,
  }
})