import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import Measure from 'react-measure'
import colors from '../colors'

import Center from './Center'
import Phone from './Phone'
import Rings from './Rings'
import Step from './Step'

import payments from '../img/payments-no-card.png'
import browse from '../img/browse.png'
import deposit from '../img/deposit.png'
import logo from '../img/logo-color.svg'

export default class Splash extends React.PureComponent {
  state = {
    height: undefined
  }
  render () {
    const { height } = this.state
    
    const isMobile = window.innerWidth < 600
    const stepHeight = Math.min(900, height * 0.8)
    const phoneHeight = Math.min(height * 0.75, 812)
    return (
      <Measure
        bounds
        onResize={contentRect => {
          this.setState({ height: window.innerHeight})
        }}
      >
        {({ measureRef }) => (
          <div className={css(styles.onboardContainer)} ref={measureRef}>
            <div style={{
              position: 'relative',
            }}>
              <div style={{ height: height * 0.25 }} />
              <Center>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: 900,
                  margin: '0 auto',
                  width: '100%',
                }}>
                  <div>
                    <div>
                      <div style={{
                        height: height * 0.12,
                        width: height * 0.12,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 24,
                        position: 'relative',
                      }}>
                        <Rings />
                        <img src={logo} style={{ height: 128, width: 128 }} />
                      </div>
                    </div>

                    <div style={{ height: 24 }} />
                    <h1 style={{ textAlign: 'left', position: 'relative' }}>
                      <span>An experience light years ahead of the curve.</span>
                    </h1>
                  </div>
                </div>
              </Center>
              <div style={{ height: height * 0.1 }} />
            </div>

            <div style={{
              position: 'relative',
            }}>
              <div style={{
                alignItems: 'center',
                display: 'flex',
                height: isMobile ? undefined : stepHeight,
              }}>
                <Center>
                  <Step
                    text={`No exchanges and no awkward selfies with your passport, just add a card to get started.`}
                    narrow={true}
                    number={1}
                    numberColor={`radial-gradient(at top right, ${colors.orange}, ${colors.primary})`}
                    rings={true}
                    title="Add a debit card."
                  >
                    <div style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      position: 'relative',
                      marginTop: isMobile ? 24 : 0,
                    }}>
                      <Phone
                        color={colors.greyLight}
                        height={phoneHeight}
                        frameless={true}
                        orbit={true}
                      >
                        <img
                          alt="add-card"
                          src={payments}
                          style={{
                            height: '100%',
                            position: 'relative',
                          }}
                        />
                      </Phone>
                    </div>
                  </Step>
                </Center>
              </div>

              {isMobile && <div style={{ height: 96 }} />}

              <div style={{
                alignItems: 'center',
                display: 'flex',
                height: isMobile ? undefined : stepHeight,
              }}>
                <Center>
                  <Step
                    text={`Cryptocurrencies are confusing. That’s why Astro lets you use USD for everything.`}
                    narrow={true}
                    number={2}
                    numberColor={`radial-gradient(at top right, ${colors.primary}, ${colors.purple})`}
                    reverse={true}
                    title="Deposit dollars."
                  >
                    <div style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginTop: isMobile ? 24 : 0,
                    }}>
                      <Phone
                        color={colors.greyLight}
                        height={phoneHeight}
                        frameless={true}
                        orbit={true}
                      >
                        <img
                          alt="deposit"
                          src={deposit}
                          style={{
                            height: '100%',
                          }}
                        />
                      </Phone>
                    </div>
                  </Step>
                </Center>
              </div>

              {isMobile && <div style={{ height: 96 }} />}

              <div style={{
                alignItems: 'center',
                display: 'flex',
                height: isMobile ? undefined : stepHeight,
              }}>
                <Center>
                  <Step
                    text={`Astro’s fully featured browser works with all web3 dapps on Ethereum.`}
                    narrow={true}
                    number={3}
                    numberColor={`radial-gradient(at top right, ${colors.purple}, #021c7b)`}
                    title="Explore the decentralized world."
                  >
                    <div style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: isMobile ? 24 : 0,
                    }}>
                      <Phone
                        color={colors.greyLight}
                        height={phoneHeight}
                        frameless={true}
                        orbit={true}
                      >
                        <img
                          alt="browser"
                          src={browse}
                          style={{
                            height: '100%',
                          }}
                        />
                      </Phone>
                    </div>
                  </Step>
                </Center>
              </div>

            </div>
            <div style={{ height: height * 0.1 }} />
          </div>
        )}
      </Measure>
    )
  }
}

const styles = StyleSheet.create({
  onboardContainer: {
    position: 'relative',
  },
  highlight: {
    position: 'relative',
    ':before': {
      content: '""',
      position: 'absolute',
      right: -4, bottom: -4, left: -4,
      // backgroundColor: 'yellow',
      height: '2.5rem',
      // clipPath: 'polygon(0 20%, 100% 0%, 100% 100%, 0% 100%)',
    },
  },
  highlightText: {
    position: 'relative',
  },
  ring: {
    position: 'absolute',
    height: '200%',
    width: '200%',
    border: `2px solid ${colors.offWhite}`,
    top: 'calc(-50% - 2px)',
    left: 'calc(-50% - 2px)',
    borderRadius: '100%',
  }
})
