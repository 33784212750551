import React from 'react'

import Abstraction from './components/Abstraction'
import Footer from './components/Footer'
import Join from './components/Join'
import Onboard from './components/Onboard'
import Splash from './components/Splash'
import Stack from './components/Stack'
import colors from './colors';

export default class App extends React.Component {
  render () {
    return (
      <div style={{
        width: '100%',
        overflow: 'hidden',
      }}>
        <Splash />
        <Onboard />
        <Abstraction />
        <Stack />
        <Join />
        <Footer />
      </div>
    )
  }
}

/*
        <Onboard />
        <Abstraction />
        <Stack />
        <Join />
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '25vh',
          background: colors.black,
        }}>
        </div>
        */

/*
function App() {
  return (
    <Splash />
  )
  /*
  return (
    <div style={{
      position: 'absolute',
      top: 0, right: 0, bottom: 0, left: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <img
        alt="astro-logo"
        src={logo}
        style={{
          height: '20vmin',
          width: '20vmin',
        }}
      />
      <span style={{
        fontSize: '10vmin',
        fontWeight: 700,
      }}>astro</span>
      <div style={{ height: '2vmin' }} />
      <span style={{ fontSize: '5vmin' }}>The future is coming soon™</span>
    </div>
  )

}
*/
