import React from 'react'
import { css, StyleSheet } from 'aphrodite'

import usdc from '../img/usdc.svg'

export default function ({ children }) {
  return (
    <div className={css(styles.orbitContainer)}>
      <div className={css(styles.system)}>
        <div className={css(styles.sun)}>
          <img alt="usdc" src={usdc} />
        </div>
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  orbitContainer: {

  },
  sun: {
    height: 96,
    width: 96,
  }
})