import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import Measure from 'react-measure'
import colors from '../colors'

import Center from './Center'
import Phone from './Phone'
import Rings from './Rings'
import Step from './Step'
import USDCOrbit from './USDCOrbit'

import usdc from '../img/usdc.svg'
import compound from '../img/compound.svg'
import opyn from '../img/opyn.svg'
import maker from '../img/maker.png'
import unchained from '../img/unchained.png'
import kitty from '../img/crypto-kitties.png'
import mana from '../img/mana.png'

export default class Splash extends React.PureComponent {
  state = {
    height: undefined,
    dappIcon: compound,
    bg: '#202322',
  }

  render () {
    const { height } = this.state

    const isMobile = window.innerWidth < 900

    return (
      <Measure
        bounds
        onResize={contentRect => {
          this.setState({ height: Math.min(window.innerHeight, 1200)})
        }}
      >
        {({ measureRef }) => (
          <div
            ref={measureRef}
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: colors.offWhite,
              overflow: 'hidden',
              // height: !isMobile ? height : undefined,
              position: 'relative',
              padding: '128px 0',
            }}
          >
            <Center>
              <div style={{
                alignItems: 'center',
                display: 'flex',
                position: 'relative',
                maxWidth: 900,
                margin: '0 auto',
                width: '100%',
              }}>
                <div>
                  <div className={css(styles.dappsContainer)}>

                    <div className={css(styles.dappContainer)}>
                      <div className={css(styles.dapp)} style={{ backgroundColor: '#4FC2A0' }}>
                        <img src={opyn} style={{ height: '50%' }} />
                      </div>
                    </div>

                    <div className={css(styles.dappContainer)}>
                      <div className={css(styles.dapp)}>
                        <img src={kitty} style={{ height: '100%' }} />
                      </div>
                    </div>

                    <div className={css(styles.dappContainer)}>
                      <div className={css(styles.dapp)} style={{ backgroundColor: '#202322' }}>
                        <img src={compound} style={{ height: '50%' }} />
                      </div>
                    </div>

                    <div className={css(styles.dappContainer)}>
                      <div className={css(styles.dapp)}>
                        <img src={mana} style={{ height: '100%' }} />
                      </div>
                    </div>

                    <div className={css(styles.dappContainer)}>
                      <div className={css(styles.dapp)} style={{ backgroundColor: '#54697A' }}>
                        <img src={maker} style={{ height: '50%' }} />
                      </div>
                    </div>

                    <div className={css(styles.dappContainer)}>
                      <div className={css(styles.dapp)}>
                        <img src={unchained} style={{ height: '100%' }} />
                      </div>
                    </div>


                  </div>
                  <div style={{ height: 24 }} />
                  <h1 style={{
                    position: 'relative',
                  }}>Your dollar is good <span className={css(styles.gradientText)}>everywhere.</span></h1>
                  <div style={{ paddingTop: 24, }}>
                    <span style={{
                      lineHeight: 2,
                      fontSize: '1.5rem'
                    }}>
                      {`Astro is the first wallet to feature complete economic abstraction. Even if the dapp doesn't accept USD, Astro lets you pay with USD.`}
                    </span>
                  </div>
                </div>


              </div>
            </Center>
          </div>
        )}
        </Measure>
    )
  }
  /*

                    <div style={{
                    height: 128,
                    width: 128,
                    display: isMobile ? 'none' : 'block',
                  }}>
                    <img src={usdc} />
                  </div>

                  <div style={{
                  display: isMobile ? 'none' : 'block',
                  position: 'relative',
                  top: isMobile ? 64 : height / 5,
                }}>
                  <Phone
                    backgroundColor={this.state.bg}
                    color={colors.greyDark}
                    height={Math.min(height, 400 / 0.46)}
                    orbit={true}
                  >
                    <div style={{
                      height: `calc(100% - ${height / 10}px)`,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      <img
                        alt="dapp"
                        src={this.state.dappIcon}
                        style={{
                          height: 96,
                          width: 96,
                        }}
                      />
                      <div style={{ height: 24 }} />
                      <span style={{
                        color: colors.white,
                        letterSpacing: 2,
                        textTransform: 'uppercase',
                      }}>Compound</span>
                    </div>
                  </Phone>
                </div>

                <div style={{ width: 64 }} />
  */
}

const styles = StyleSheet.create({
  abstractionContainer: {
    position: 'relative',
  },
  gradientText: {
    background: `-webkit-linear-gradient(45deg, ${colors.purple}, ${colors.primary}, ${colors.orange})`,
    fontWeight: 900,

    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  highlight: {
    position: 'relative',
    ':before': {
      content: '""',
      position: 'absolute',
      right: -4, bottom: -4, left: -4,
      // backgroundColor: 'yellow',
      height: '2.5rem',
      // clipPath: 'polygon(0 20%, 100% 0%, 100% 100%, 0% 100%)',
    }
  },
  highlightText: {
    position: 'relative',
  },
  dappsContainer: {
    display: 'flex',
    position: 'relative',
    ':after': {
      content: '""',
      position: 'absolute',
      top: 0, right: 0, bottom: 0, left: 0,
      zIndex: 3,
      background: `linear-gradient(90deg, #f3f6f700, #f3f6f700, #f3f6f7)`,
    },
    '@media only screen and (max-width: 600px)': {
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      ':after': {
        background: `linear-gradient(180deg, #f3f6f700, #f3f6f700, #f3f6f7)`,
      }
    },
  },
  dappContainer: {
    height: 128,
    width: 128,
    background: '#FFF',
    borderRadius: 24,
    marginRight: 24,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media only screen and (max-width: 600px)': {
      height: 96,
      width: 96,
      marginRight: 12,
      marginBottom: 12,
    }
  },
  dapp: {
    height: '75%',
    width: '75%',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})