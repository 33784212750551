import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import Cookies from 'js-cookie'
import colors from '../colors'

import logo from '../img/logo-color.svg'

import Button from './Button'
import Center from './Center'

export default class Join extends React.PureComponent {
  state = {
    email: '',
  }

  constructor (props) {
    super(props)
    const kolId = Cookies.get('kola.125332.cid')
    if (kolId) {
      this.state.kolId = kolId
    }
  }

  render () {
    return (
      <div className={css(styles.joinContainer)} style={{
        background: '#050505',//`radial-gradient(circle at 50% 194px, rgb(29, 16, 25), rgb(34, 19, 35))`,
      }}>
        <Center>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '0 auto',
          }}>
            <div style={{
              position: 'relative',
              height: 128,
              width: 128,
            }}>
              <img
                alt="astro-logo"
                src={logo}
                style={{
                  height: 128,
                  width: 128,
                  position: 'relative',
                }}
              />
            </div>
            <div style={{ height: 6 }} />
            <h1 style={{
              color: colors.white,
              textAlign: 'center',
              position: 'relative',
            }}>astro</h1>
          </div>

          <h3 style={{
            color: colors.white,
            textAlign: 'center',
            position: 'relative',
          }}>The future awaits.</h3>

        </Center>
      </div>
    )
  }

  onEmailChange = e => {
    const { target: { value }} = e
    this.setState({ email: value })
  }
}

const styles = StyleSheet.create({
  joinContainer: {
    minHeight: '100vh',
    paddingBottom: 64,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
  },
  inputContainer: {
    display: 'flex',
    maxWidth: 600,
    margin: '0 auto',
    width: '100%',
    position: 'relative',
    '@media only screen and (max-width: 600px)': {
      flexDirection: 'column',
      height: 120,
    }
  }
})
