import React from 'react'
import { css, StyleSheet } from 'aphrodite'

export default function ({ children }) {
  return (
    <div className={css(styles.center)}>
      {children}
    </div>
  )
}

const styles = StyleSheet.create({
  center: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: 1200,
    width: 'calc(100% - 48px)',
    margin: '0 auto',
    position: 'relative',
    paddingLeft: 24,
    paddingRight: 24,
  }
})