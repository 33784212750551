import React from 'react'

export default function ({ backgroundColor, color, text, type, href, onPress, }) {
  const style = {
    background: backgroundColor,
    borderRadius: 4,
    boxShadow: '0px 4px 8px rgba(0,0,0,.25',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 54,
    paddingLeft: 24,
    paddingRight: 24,
    border: 0,
  }
  const inner = <span style={{
    color,
    fontSize: 12,
    fontWeight: 900,
    letterSpacing: 2,
    textTransform: 'uppercase',
  }}>
    {text}
  </span>
  const base = href ?
    <a href={href} style={{...style, textDecoration: 'none' }}>{inner}</a>
    : <button style={style} type={type || 'button'} onClick={onPress}>{inner}</button>
  return (
    <React.Fragment>
      {base}
    </React.Fragment>
  )
}
