import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import Measure from 'react-measure'
import colors from '../colors'

import Button from './Button'
import Center from './Center'
import Dollar from './Dollar'
import Logo from './Logo'
import Phone from './Phone'

import home from '../img/home.png'
import pay from '../img/pay.png'
import payments from '../img/payments.png'
import wallet from '../img/wallet.png'

export default class Splash extends React.PureComponent {
  state = {
    height: undefined
  }
  render () {
    const { height } = this.state

    const isMobile = window.innerWidth < 900

    const maxPhoneHeight = isMobile ? window.innerWidth / 2 / 0.46 : 300 / 0.46
    const phoneHeight = Math.min(height * 0.75, maxPhoneHeight)

    return (
      <Measure
        bounds
        onResize={contentRect => {
          this.setState({ height: window.innerHeight * 0.9 })
        }}
      >
        {({ measureRef }) => (
          <div
            className={css(styles.splashContainer)}
            ref={measureRef}
            style={{
              height: height ? height : '100vh',
              zIndex: 3,
            }}
          >
            <div className={css(styles.background)}>
              <div className={css(styles.ray)} />
              <div className={css(styles.planet, styles.planet1)} />
              <div className={css(styles.planet, styles.planet2)} />
              <div className={css(styles.planet, styles.planet3)} />
            </div>
            
            <div>
            <Center>
              <div className={css(styles.topBar)}>
                <Logo
                  size={36}
                />
                <div style={{ flex: 1 }} />
                <span
                  onClick={() => {
                    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                  }}
                  style={{
                  fontSize: 12,
                  fontWeight: 900,
                  letterSpacing: 2,
                  cursor: 'pointer',
                }}>EARLY ACCESS</span>
              </div>
            </Center>
            </div>

            <Center>

              <div className={css(styles.splashContent)}>

                <div className={css(styles.textContainer)}>
                  <h1>Use every dapp with dollars.</h1>
                  <div style={{ height: 24 }} />
                  <span style={{
                    fontSize: '1.5rem',
                    lineHeight: 1.5,
                  }}>{`
                    Skip the learning curve and jump straight into to the decentralized world with Astro.
                  `}</span>
                  <div style={{ height: 48 }} />
                  <Button
                    backgroundColor={colors.white}
                    color={colors.primary}
                    text="Get Early Access"
                    onPress={() => {
                      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                    }}
                  />
                </div>

                <div className={css(styles.phonesContainer)}>
                  <div style={{
                    position: 'relative',
                    right: '-10%',
                    zIndex: 2,
                  }}>
                    <Phone
                      backgroundColor={colors.black}
                      color={colors.black}
                      height={phoneHeight}
                    >
                      <img
                        alt="wallet"
                        src={payments}
                        style={{
                          width: '100%',
                        }}
                      />
                    </Phone>
                  </div>
                  <Phone
                    color={colors.black}
                    height={phoneHeight * 0.9}
                  >
                    <img
                      alt="pay"
                      src={pay}
                      style={{
                        width: '100%',
                      }}
                    />
                  </Phone>
                </div>

              </div>
            </Center>
          </div>
        )}
      </Measure>
    )
  }
}

const styles = StyleSheet.create({
  background: {
    position: 'absolute',
    top: 0, right: 0, bottom: 0, left: 0,
    //background: `url(${require('../img/splash-bg.png')})`,
    background: `linear-gradient(45deg, ${colors.purple}, ${colors.primary})`,
    //background: `linear-gradient(45deg, ${colors.purple}, ${colors.primary} 50%, ${colors.orange})`,
    // backgroundSize: 'cover',
    clipPath: `polygon(0 0, 100% 0, 100% 80%, 0% 100%)`,
    // clipPath: 'circle(37.2% at 84% 14%)',
  },
  ray: {
    position: 'absolute',
    top: 0, right: 0, bottom: 0, left: 0,
    background: `linear-gradient(45deg, ${colors.purple}, ${colors.primary} 75%, ${colors.orange})`,
    clipPath: `polygon(100% 0, 100% 20%, 70% 100%, 0 100%, 0 40%, 70% 0)`,
    '@media only screen and (max-width: 900px)': {
      clipPath: `polygon(100% 0, 100% 100%, 0% 100%, 0 100%, 0 0%, 100% 0)`,
    }
  },

  splashContainer: {
    color: colors.white,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    '@media only screen and (max-width: 900px)': {
      height: 'auto',
    }
  },
  splashContent: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    '@media only screen and (max-width: 900px)': {
      flexDirection: 'column',
    }
  },

  phonesContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    '@media only screen and (max-width: 900px)': {
      left: '-5%',
    }
  },
  phoneFront: {

  },
  phoneBack: {
    
  },

  textContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: '-7.5%',
    minWidth: 350,
    '@media only screen and (max-width: 900px)': {
      paddingTop: 96,
      paddingBottom: 96,
      minWidth: 'auto',
    }
  },

  topBar: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: 96,
  },
})