import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import colors from '../colors'

export default class Footer extends React.Component {
  render () {
    return (
      <div style={{
        background: '#000000',
        color: '#FFF',
        padding: 24,
        paddingBottom: 64,
        paddingTop: 64,
      }}>
        <div className={css(styles.content)}>


          <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}>
            <span style={{
              fontWeight: 900,
              marginBottom: 12,
            }}>Contact</span>
            <span>info@astrowallet.io</span>
            <span>press@astrowallet.io</span>
          </div>

          <div style={{ width: 24, height: 24, }} />

          <div className={css(styles.social)}>
            <span style={{
              fontWeight: 900,
              marginBottom: 12,
            }}>Social</span>
            <a href="https://twitter.com/astro_wallet">Twitter</a>
            <a href="https://medium.com/@astrowallet">Medium</a>
          </div>
        </div>

        <div style={{ height: 24,}} />
        <div className={css(styles.copyright)}>
            <span style={{ flex: 1}}>Based in San Francisco, CA.</span>
            <div style={{ width: 24, height: 24, }} />
            <span style={{ flex: 1 }}>Copyright 2019, Comet X Inc.</span>
        </div>
      </div>
    )
  }
}

const styles = StyleSheet.create({
  footerContainer: {

  },
  content: {
    alignItems: 'center',
    overflow: 'hidden',
    display: 'flex',
    width: '100%',
    maxWidth: 800,
    margin: '24px auto',
    opacity: 0.5,
    lineHeight: 1.3,
  },
  contact: {
    flex: 1,
    textAlign: 'right',
  },
  social: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  socialIcon: {
    height: 64,
    width: 64,
    marginLeft: 12,
    background: colors.white,
    borderRadius: 24,
    cursor: 'pointer',

  },
  copyright: {
    opacity: 0.5,
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    display: 'flex',
  }
})