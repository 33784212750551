import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import Measure from 'react-measure'
import colors from '../colors'

import Center from './Center'
import Rings from './Rings'

import eth from '../img/eth.svg'
import usdc from '../img/usdc-white.svg'
import zrx from '../img/zrx.svg'

export default class Stack extends React.PureComponent {
  state = {
    width: 0,
  }
  render () {
    const cellHeight = 400
    return (
      <div className={css(styles.stackContainer)}>
        <div className={css(styles.content)}>
          <Center>
              <div style={{ height: 128 }} />
              <div className={css(styles.stack)}>
                <div className={css(styles.ball, styles.tiny)} />
                <div className={css(styles.ball, styles.small)} />

                <div
                  className={css(styles.ball, styles.med)}
                >
                  <div
                    className={css(styles.logo)}
                    style={{ background: 'radial-gradient(circle at top right, #0075d1, #361ac3)' }}
                  >
                    <img src={usdc} style={{ height: '60%', width: '60%' }} />
                  </div>
                </div>

                <div className={css(styles.ball, styles.large)}>
                  <div
                    className={css(styles.logo)}
                    style={{ background: 'radial-gradient(circle at top right, #cb7eff, #8a7eff)' }}
                  >
                    <Rings />
                    <img src={eth} style={{ height: '60%', width: '60%' }} />
                  </div>
                </div>

                <div
                  className={css(styles.ball, styles.med)}
                >
                  <div
                    className={css(styles.logo)}
                    style={{ background: 'radial-gradient(circle at top right, #444, #000)' }}
                  >
                    <img src={zrx} style={{ height: '60%', width: '60%' }} />
                  </div>
                </div>

                <div className={css(styles.ball, styles.small)} />
                <div className={css(styles.ball, styles.tiny)} />
              </div>
              <div style={{ height: 64 }} />
              <div className={css(styles.description)}>
                <h1>Built with the best.</h1>
                <div style={{ height: 12 }} />
                <span style={{
                  fontSize: '1.5rem',
                  lineHeight: 2,
                }}>Astro's stack includes Ethereum, 0x, and USDC.</span>
              </div>
          </Center>
        </div>
      </div>
    )
  }
}

const styles = StyleSheet.create({
  ball: {
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //boxShadow: '0px 8px 16px rgba(0,0,0,.05)',
    position: 'relative',
    margin: '24px 0',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    '@media only screen and (max-width: 600px)': {
     // paddingBottom: 96,
    }
  },
  description: {
    margin: '0 auto',
    maxWidth: 600,
    position: 'relative',
    textAlign: 'center',
    '@media only screen and (max-width: 600px)': {
      textAlign: 'left',
      paddingBottom: 96,
    }
  },
  tiny: {
    height: 20,
    width: 20,
    background: `radial-gradient(at top right, ${colors.primary}, ${colors.purple})`,
    zIndex: 1,
    '@media only screen and (max-width: 600px)': {
      display: 'none',
    }
  },
  small: {
    height: 36,
    width: 36,
    background: `radial-gradient(at top right, ${colors.orange}, ${colors.primary})`,
    zIndex: 1,
    zIndex: 1,
    '@media only screen and (max-width: 600px)': {
      display: 'none',
    }
  },
  med: {
    height: 108,
    width: 108,
    zIndex: 1,
    '@media only screen and (max-width: 600px)': {
      height: 64,
      width: 64,
    }
  },
  large: {
    height: 180,
    width: 180,
    '@media only screen and (max-width: 600px)': {
      height: 96,
      width: 96,
    }
  },
  cell: {
    flex: 1,
    height: 400,
  },
  cellColor: {
    backgroundColor: colors.primary,
  },
  row: {
    display: 'flex',
  },
  stack: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around',
  },
  stackContainer: {
    // backgroundColor: colors.offWhite,
    overflow: 'hidden',
  },
  logo: {
    height: '100%',
    width: '100%',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
})