import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import colors from '../colors'

import Rings from './Rings'

import debris from '../img/debris.svg'
import shipOrbit from '../img/ship-orbit.svg'

export default class Phone extends React.PureComponent {
  state = {
    height: undefined
  }
  render () {
    const {
      backgroundColor,
      children,
      color,
      height,
      frameless,
      orbit,
    } = this.props

    const innerSize = height * 0.8
    const outerSize = height * 1.2
    return (
      <div className={css(styles.phoneContainer)}>
        {false && (
          <div style={{
            position: 'absolute',
            height: innerSize / 2,
            width: innerSize / 2,
            top: 0, right: 0, bottom: 0, left: 0,
            margin: 'auto',
          }}>
            <Rings />
          </div>
        )}
        <div
          className={css(styles.phone)}
          style={{
            backgroundColor,
            borderColor: color,
            borderRadius: frameless ? height * 0.025 : height * 0.075,
            borderWidth: frameless ? 1 : height * 0.02,
            height,
            width: height * 0.46,
          }}
        >
          {children}
        </div>
      </div>
    )
  }
}

const orbitKeyframes = {
  'from': {
    transform: 'rotate(0deg)',
  },
  'to': {
    transform: 'rotate(360deg)',
  }
}

const debrisKeyframes = {
  'from': {
    transform: 'rotate(0deg)',
  },
  'to': {
    transform: 'rotate(360deg)',
  }
}

const styles = StyleSheet.create({
  debris: {
    position: 'absolute',
    top: 0, bottom: 0,
    margin: 'auto',
    animationName: [debrisKeyframes],
    animationDuration: '60s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  orbitContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: '0', right: '0', bottom: '0', left: '0',
  },
  orbit: {
    position: 'absolute',
    top: 0, bottom: 0,
    margin: 'auto',
    animationName: [orbitKeyframes],
    animationDuration: '20s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  orbitImg: {
    height: '100%',
  },
  phoneContainer: {
    position: 'relative',
  },
  phone: {
    borderStyle: 'solid',
    boxShadow: '0px 8px 16px rgba(0,0,0,.05)',
    position: 'relative',
    overflow: 'hidden',
  }
})