import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import colors from '../colors'

import Rings from './Rings'

export default class Phone extends React.PureComponent {
  state = {
    height: undefined
  }
  render () {
    const {
      children,
      invert,
      reverse,
      narrow,
      number,
      numberColor,
      text,
      title,
    } = this.props
    return (
      <div
        className={css([
          styles.stepContainer,
          narrow ? styles.narrow : undefined,
        ])}
        style={{
          flexDirection: reverse ? 'row-reverse' : 'row',
        }}
      >
        <div className={css(styles.description)}>
        <div
            className={css([
              styles.stepNumber,
              invert ? styles.numberInvert : undefined,
            ])}
          >0{number}</div>
          <div style={{ height: 24 }} />
          <h2>{title}</h2>
          <div style={{ height: 24 }} />
          <span style={{ fontSize: '1.5rem', lineHeight: 2 }}>{text}</span>
        </div>

        <div className={css(styles.visual)}>
          {children}
        </div>
      </div>
    )
  }
}

/*

*/

const styles = StyleSheet.create({
  description: {
    flex: 1,
    position: 'relative',
    zIndex: 2,
    '@media only screen and (max-width: 600px)': {
      flex: 'none',
    }
  },
  visual: {
    flex: 1,
    position: 'relative',
    '@media only screen and (max-width: 600px)': {
      flex: 'none',
    }
  },

  stepContainer: {
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    '@media only screen and (max-width: 600px)': {
      flexDirection: 'column',
    }
  },
  narrow: {
    maxWidth: 900,
    margin: '0 auto',
  },
  stepNumber: {
    color: colors.greyLight,
    fontFamily: `'Oswald', sans-serif`,
    fontSize: '8rem',
    lineHeight: 1,
    '@media only screen and (max-width: 600px)': {
      fontSize: '6rem',
    }
  },
  numberInvert: {
    color: colors.white,
  }
})