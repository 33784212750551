export default {
  orange: '#FF9458',
  primary: '#FF237B',
  purple: '#B723FF',

  white: '#FFFFFF',
  offWhite: '#f3f6f7',
  black: '#000',

  grey: '',
  greyDark: '#3B484B',
  greyLight: '#E8ECED',
}

/*
'#FF9458', '#FF237B', '#B723FF',
*/