import React from 'react'

import logo from '../img/logo.svg'

export default function ({ size, vertical }) {
  return (
    <div style={{
      display: 'flex',
      flexDirection: vertical ? 'column' : 'row',
      alignItems: 'center',
    }}>
      <div style={{
        height: size,
        width: size,
      }}>
          <img
            alt="astr-logo"
            src={logo}
            style={{
              height: '100%',
            }}
          />
      </div>
      
      <div style={{
        height: vertical ? size / 3 : 0,
        width: vertical ? 0 : size / 3,
      }} />

      <span style={{
        fontWeight: 900,
        fontSize: size * 0.67,
        lineHeight: `${size * 0.67}px`,
      }}>astro</span>

    </div>
  )
}