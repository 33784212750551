import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import colors from '../colors'

export default function ({ height, width }) {
  return (
    <svg height={height} width={width} viewBox="0 0 290 512">
      <defs>
        <radialGradient cx="85.5792826%" cy="11.2776933%" fx="85.5792826%" fy="11.2776933%" r="124.648038%" id="gradient">
            <stop stop-color={colors.primary} offset="0%" />
            <stop stop-color={colors.primary} offset="100%" />
        </radialGradient>
     </defs>
      <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="dollar-sign" fill="url(#gradient)">
          <path
            d="M212.9,242.1 L96.6,208.9 C80.8,204.4 68,191.7 65.5,175.4 C61.6,150 81.3,128 106,128 L179.8,128 C195.7,128 211.3,133 224.2,142.1 C230.6,146.6 239.2,145.9 244.7,140.4 L267.6,117.5 C274.4,110.7 273.7,99.3 266.1,93.4 C241.4,74.3 211.4,64 179.8,64 L177,64 L177,16 C177,7.2 169.8,0 161,0 L129,0 C120.2,0 113,7.2 113,16 L113,64 L110.5,64 C61.3,64 15.9,95.8 4.1,143.6 C-9.8,199.8 24.3,254.8 77.1,269.9 L193.4,303.1 C209.2,307.6 222,320.3 224.5,336.6 C228.4,362 208.7,384 184,384 L110.2,384 C94.3,384 78.7,379 65.8,369.9 C59.4,365.4 50.8,366.1 45.3,371.6 L22.4,394.5 C15.6,401.3 16.3,412.7 23.9,418.6 C48.5,437.7 78.5,448 110.2,448 L113,448 L113,496 C113,504.8 120.2,512 129,512 L161,512 C169.8,512 177,504.8 177,496 L177,448 L179.5,448 C228.7,448 274.1,416.2 285.9,368.4 C299.8,312.2 265.7,257.2 212.9,242.1 Z"
          />
        </g>
      </g>
    </svg>
  )
}

const styles = StyleSheet.create({
  dollar: {

  }
})